import { Button, Input } from "@material-tailwind/react";
import apiServices from "../ApiServises";
import { useState, useEffect } from "react";
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import {useCookies} from 'react-cookie';
export default function ButtonDefault() {
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [verifyAccountLoading, setVerifyAccountLoading] = useState(false);
    const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['user_id']);    
    const history = useNavigate();
    const notifyOtpSent = () => toast.success('OTP Sent Successfully');
    const notifyOtpSentFail = () => toast.error('OTP could not be sent');
    const notifyOtpVerified = () => toast.success('OTP verified');
    const notifyOtpVerifiedFaild = () => toast.error('Something went wrong please try later');
    const notifyWrongOtp = () => toast.error('Wrong OTP');
    const notifyEnterOtp = () => toast.error('Please Enter OTP');
    const notifyVerified = () => toast.success('You are already logged in');
    const location = useNavigate();
    const token = cookies.user_id;

  useEffect(() => {
    function verifyToken () {
        try {
          apiServices.post('verify-token',{ token }).then(function (response) {
          if(response.status === 203) {
              removeCookie('user_id', { path: '/' });
              location('/');
             return false;
           }
           if (response.status === 200) {
            location('/dashboard');
            notifyVerified();
             return true;
           }  
          })
        } catch (error) {
          console.error(error);
          return false;
        }
      };
      verifyToken();
  }, []);

  const handleVerifyAccount = async () => {
      try {
        setVerifyAccountLoading(true);
       await apiServices.post("verify_account").then((response) => {
            if(response.status === 200) {
              notifyOtpSent();
              setShowOtpInput(true);
          }
        });
}catch (error) {
    notifyOtpSentFail();
      console.error(error);
    } finally {
        setVerifyAccountLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
      if (!otp || otp.length !== 4) {
        setOtpError('Please enter OTP');
        notifyEnterOtp();
        return;
      }
    try {
        setVerifyOtpLoading(true);
     await apiServices.post("verify_admin_otp", { otp: otp }).then((response) => {
        if(response.status === 200) {
            // document.cookie = `user_id=${response.data.token}; expires=${new Date(Date.now() + 86400000).toUTCString()}; path=/`;
            setCookie('user_id', response.data.token, {
                expires: new Date(Date.now() + 86400000),
                path: '/',
              });
            notifyOtpVerified();
            localStorage.setItem("isAuth", true);
            setOtpError('');
            setTimeout(() => {
                history('/dashboard');
            },2000);
        }else{
            setOtpError('Invalid OTP');
            notifyWrongOtp();
        }
    });
      // Handle successful OTP verification
    } catch (error) {
      console.error(error);
      notifyOtpVerifiedFaild();
      setOtpError('Please try later.');
    } finally {
      setVerifyOtpLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen w-screen">
    <Toaster />
      {showOtpInput ? (
        <div className="flex flex-col items-center gap-5">
        <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderSeparator={() => <span>  </span>}
      inputStyle={{ width: '50px', height: '60px', fontSize: '24px', border: '1px solid', borderRadius: '15px', margin: '10px' }}
      renderInput={(props) => <input {...props} />}
    />
    {otpError && <div className="text-red-500 text-sm">{otpError}</div>}
          <Button onClick={handleVerifyOtp} loading={verifyOtpLoading}>Verify OTP</Button>
        </div>
      ) : (
        <Button onClick={handleVerifyAccount}  loading={verifyAccountLoading} >Verify Account</Button>
      )}
    </div>
  );
}