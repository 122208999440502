import React, { useEffect, useState } from 'react';
import { Input, Button, Textarea  } from "@material-tailwind/react";
import apiServices from '../ApiServises';
import { useCookies } from 'react-cookie';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
export default function DailyShringar() {
  const [image, setImage] = useState(null);
  const [shlok, setShlok] = useState('');
  const [meaning, setMeaning] = useState('');
  const [cookies, setCookie,removeCookie] = useCookies(['user_id']);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = cookies.user_id;
  const updateSuccess = () => toast.success('Data updated successfully');
  const location = useNavigate();
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file)); // Create a preview URL
    }
  };
 

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append('image', image);
    formData.append('shlok', shlok);
    formData.append('meaning', meaning);
    formData.append('token', token);
    try {
      apiServices.post('update-daily-shringar', formData, {
          // Set appropriate headers if needed
          headers: {
            'Content-Type': 'multipart/form-data', // For uploading files
          },
        }).then(response => {
            if(response.status === 200) {
                updateSuccess();
                apiServices.post('get-daily-shringar', { token }).then((response) => {
                    setShlok(response.data.shlok);
                    setMeaning(response.data.meaning);
                })
            }
          // Handle success (e.g., clear form, display confirmation message)
        }).catch(error => {
          console.error('Error submitting data:', error);
          // Handle errors gracefully (e.g., display an error message)
        }).finally(() =>{
          setLoading(false);
        });
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  useEffect( () =>  {
    apiServices.post('get-daily-shringar', { token }).then((response) => {
      setShlok(response.data.shlok);
      setMeaning(response.data.meaning);
    })
  }, []);

  return (
    <div className="p-4 w-3/4 mx-auto">
    <Toaster />
      <h2 className="text-lg font-bold mb-4">Daily Shringar</h2>
      <div>

      <p>Current Image</p>
<img src='https://www.ujjainonwheels.in/api/public/daily/${image}'/>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
        {imagePreview && (
          <div className="mt-4 flex justify-center">
            <img
              src={imagePreview}
              alt="Image Preview"
              className="w-1/2  h-auto rounded-lg"
            />
          </div>
        )}
          <label className="block mb-2 text-sm font-medium text-gray-700">Upload Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="block w-full text-sm text-gray-500 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:ring focus:ring-opacity-50"
          />
        </div>
        <div>
          <Input
            type="text"
            label="Shlok"
            value={shlok}
            onChange={(e) => setShlok(e.target.value)}
          />
        </div>
        <div>
          <Textarea 
            label="Meaning"
            value={meaning}
            onChange={(e) => setMeaning(e.target.value)}
          />
        </div>
        <div className="flex justify-end">
        <Button onClick={() => location('/dashboard')} className="rounded-md bg-red-600 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-red-700 focus:shadow-none active:bg-red-700 hover:bg-red-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ml-2 mr-3">Cancel</Button>
        <Button type="submit" loading={loading} className=" rounded-md bg-blue-600 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-red-700 focus:shadow-none active:bg-red-700 hover:bg-red-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
          Submit
        </Button>
            
        </div>
      </form>
    </div>
  );
}