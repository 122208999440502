import { useEffect, useState } from "react";
import apiServices from "../ApiServises";
import { useCookies } from 'react-cookie';

export default function DailyShringar() {
  const [bookings, setBookings] = useState([]);
  const [cookies, setCookie,removeCookie] = useCookies(['user_id']);
  const token = cookies.user_id;
  useEffect(() => {
    apiServices.post('get-bookings', { token }).then((response) => {
        const updatedArray = response.data.user_bookings.reverse();
      setBookings(updatedArray);
    });
  }, []);
  return (
      <div className="relative flex flex-col w-3/4 overflow-scroll flex justify-center text-gray-700 bg-white shadow-md rounded-lg bg-clip-border">
      
      <table className="w-full text-left table-auto min-w-max text-slate-800">
        <thead>
          <tr className="text-slate-500 border-b border-slate-300 bg-slate-50">
          <th className="p-4">
              <p className="text-sm leading-none font-normal">Id</p>
            </th>
            <th className="p-4">
              <p className="text-sm leading-none font-normal">Name</p>
            </th>
            <th className="p-4">
              <p className="text-sm leading-none font-normal">Phone</p>
            </th>
            <th className="p-4">
              <p className="text-sm leading-none font-normal">Booking Date</p>
            </th>
            <th className="p-4">
              <p className="text-sm leading-none font-normal">Expected Date</p>
            </th>
            <th className="p-4">
              <p className="text-sm leading-none font-normal">Selected Package</p>
            </th>
          </tr>
        </thead>
        <tbody>
      {bookings.length > 0 ? (
        bookings.map((booking) => (
          <tr key={booking.id} className="hover:bg-slate-50">
          <td className="p-4">
                <p className="text-sm font-bold">{booking.id}</p>
              </td>
              <td className="p-4">
                <p className="text-sm font-bold">{booking.name}</p>
              </td>
              <td className="p-4">
                <p className="text-sm">{booking.phone}</p>
              </td>
              <td className="p-4">
                <p className="text-sm">{booking.created_at}</p>
              </td>
              <td className="p-4">
                <p className="text-sm">{booking.date}</p>
              </td>
              <td className="p-4">
                <p className="text-sm">{booking.package_name}</p>
              </td>
            </tr>
        ))
      ) : (
        <tr className="text-center">
          <td colSpan="4">Loading bookings...</td>
        </tr>
      )}
    </tbody>
      </table>
    </div>
  );
}
{/* <tr key={booking.id} className="hover:bg-slate-50">
              <td className="p-4">
                <p className="text-sm font-bold">{booking.name}</p>
              </td>
              <td className="p-4">
                <p className="text-sm">{booking.phone}</p>
              </td>
              <td className="p-4">
                <p className="text-sm">{booking.expected_date}</p>
              </td>
              <td className="p-4">
                <p className="text-sm">{booking.selected_package}</p>
              </td>
            </tr> */}