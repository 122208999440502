import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import ViewQueries from './pages/ViewQueries'
import ViewBookings from './pages/ViewBooking'
import DailyShringarDarshan from './pages/DailyShringar'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import apiServices from './ApiServises';
const ProtectedRoute = ({ children }) => {
    // axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const [cookies, setCookie,removeCookie] = useCookies(['user_id']);
    const token = cookies.user_id;
  const location = useNavigate();
  useEffect(() => {
    if (!token) {
      location('/');
    }
  }, []);
  
    // Verify token with database
    const verifyToken = async () => {
      try {
       await apiServices.post('verify-token',{ token }).then(function (response) {
        if(response.status === 203) {
            removeCookie('user_id', { path: '/' });
            location('/');
           return false;
         }
         if (response.status === 200) {
           return true;
         }  
        })
      } catch (error) {
        return false;
      }
    };
  
    const isValidToken =  verifyToken();
  
    if (!isValidToken) {
      return location('/');
    }
  
    return children;
  };

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/view-queries"
        element={
          <ProtectedRoute>
            <ViewQueries />
          </ProtectedRoute>
        }
      />
      <Route
        path="/view-bookings"
        element={
          <ProtectedRoute>
            <ViewBookings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/daily-shringar-darshan"
        element={
          <ProtectedRoute>
            <DailyShringarDarshan />
          </ProtectedRoute>
        }
      />
        </Routes>
    )
}