import React from 'react';
import Navbar from './components/Navbarr';
import {Link} from 'react-router-dom'
export default function Dashboard() {
    return (
        <>

            <Navbar/>
        <div className="container mx-auto my-10 p-4">
        <div className="text-center">
            <h1 className="text-3xl font-bold">Dashboard</h1>
        </div>
        <div className="text-center flex flex-wrap gap-10 mt-10">

       <div className="bg-white rounded-lg hover:-translate-y-2 p-4 w-64 mx-auto shadow-lg hover:cursor-pointer">
         <Link to={'/daily-shringar-darshan'} className="text-lg font-bold text-center">Update Daily Shringar Darshan Data</Link>
        
       </div>
       <div className="bg-white rounded-lg hover:-translate-y-2 p-4 w-64 mx-auto shadow-lg hover:cursor-pointer">
         <Link to={'/view-queries'} className="text-lg font-bold text-center">View User Queries</Link>
       </div>
       <div className="bg-white rounded-lg hover:-translate-y-2 p-4 w-64 mx-auto shadow-lg hover:cursor-pointer">
         <Link to={'/view-bookings'} className="text-lg font-bold text-center">View Bookings</Link>
       </div>
       
        </div>
            {/* Add your view queries, bookings, and update darshan components here */}
        </div>
        </>
    );
}