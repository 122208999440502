import axios from 'axios';
import {Cookies} from 'react-cookie';

const api = axios.create({
  baseURL: 'https://www.ujjainonwheels.in/api/api/',
});
// const [cookies, setCookie] = useCookies(['token']);
const apiServices = {
  get:  (endpoint) => {
    try {
      const response =  api.get(endpoint);
      return response;
    } catch (error) {
      console.error(error);
    }
  },
  post:  (endpoint, data) => {
    try {
    //   const token = Cookies.get('token');
    //   const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response =  api.post(endpoint, data);
      return response;
    } catch (error) {
      console.error(error);
    }
  },
};

export default apiServices;